import React from 'react'

const Home = () => {
  return (
    <div>
      <div className="h-[95vh] w-full">
        <img
          src="https://www.cclg.org.uk/write/MediaUploads/Support%20us%20section/CCAM/ccam-large-website-banner.png"
          alt="Your Alt Text"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-yellow-400 flex flex-col gap-20 items-center justify-center p-24">
        <div class="border-3 border-gray-400 p-10 max-w-screen-2xl rounded-lg bg-white shadow-lg">
          <h1 class="text-4xl font-bold text-yellow-500 mb-4 text-center">What is Childhood Cancer</h1>
          <p class="text-gray-700 text-center">Your 100-word paragraph goes here. Add your content and make it meaningful. This is a beautiful and stylish container created using Tailwind CSS. Customize the styles to match your design preferences. The outline border, shadows, and rounded corners add a pleasant look and feel. Experiment with different classes to achieve the desired visual appeal.</p>
        </div>
        <div class="border-3 border-gray-400 p-10 max-w-screen-2xl rounded-lg bg-white shadow-lg">
          <h1 class="text-4xl font-bold text-yellow-500 mb-4 text-center">Our Objectives</h1>
          <p class="text-gray-700 text-center">Your 100-word paragraph goes here. Add your content and make it meaningful. This is a beautiful and stylish container created using Tailwind CSS. Customize the styles to match your design preferences. The outline border, shadows, and rounded corners add a pleasant look and feel. Experiment with different classes to achieve the desired visual appeal.</p>
        </div>
      </div>
    </div>
  )
}

export default Home