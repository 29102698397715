import './App.css';
import {
  Route, BrowserRouter as Router, Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import AidApplication from './pages/AidApplication';
import AngelDonor from './pages/AngelDonor';
import Story from './pages/Story';
import JoinEvent from './pages/JoinEvent';
import CommitteesBoard from './pages/CommitteesBoard';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import PressRelease from './pages/PressRelease';
import DirectPayment from './pages/DirectPayment';
import PaymentType from './pages/PaymentType';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';


function App() {
  return (
    <div className='font-serif'>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aid-application" element={<AidApplication />} />
          {/* <Route path="/story" element={<Story />} /> */}
          <Route path="/payment/direct" element={<DirectPayment />} />
          {/* <Route path="/payment/type" element={<PaymentType />} /> */}
          <Route path="/donation/join-event" element={<JoinEvent />} />
          {/* <Route path="/angel-donor" element={<AngelDonor />} /> */}
          <Route path="/bulletin/press-release" element={<PressRelease />} />
          <Route path="/bulletin/gallery" element={<Gallery />} />
          <Route path="/board-of-committees" element={<CommitteesBoard />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
