import React, { useState } from 'react';

const DirectPayment = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    donationAmount: '',
    receiveGift: false,
    address: '',
    country: '',
    city: '',
    postcode: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form data submitted:', formData);
  };

  return (
    <div>
      <div className='text-center text-5xl pt-16 font-semibold font-serif'>Donation</div>
      <div className='text-center text-xl pt-4 font-semibold text-gray-500 '> Make your donation, to be angel of needy</div>

      <div className="max-w-5xl mx-auto p-10 m-10 border border-gray-300">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex items-center gap-5">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="name">
              Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="mobile">
              Mobile Number<span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
            <label className="block text-sm font-semibold text-gray-400 mb-1" >
              (Example 6012-123456789)
            </label>
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="email">
              Email Address<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="donationAmount">
              Donation Amount
            </label>
            <div className='flex items-center gap-5'>
              <div className='block text-sm font-semibold text-gray-600 mb-1'>MYR</div>
              <input
                type="number"
                id="donationAmount"
                name="donationAmount"
                value={formData.donationAmount}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
              />
            </div>
          </div>
          <div className='border border-gray-300 p-8'>
            <div className="mb-4 flex items-center gap-5">
              <input
                type="checkbox"
                id="receiveGift"
                name="receiveGift"
                checked={formData.receiveGift}
                onChange={handleChange}
              />
              <label className="block text-sm font-semibold text-gray-600">
                Do you wish to receive our appreciation gift?
              </label>
            </div>
            <div className="mb-4 flex items-center gap-5 text-nowrap">
              <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="address">
                Address<span className="text-red-500">*</span>
              </label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>
            <div className="mb-4 flex items-center gap-5 text-nowrap">
              <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="country">
                Country<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>
            <div className="mb-4 flex items-center gap-5 text-nowrap">
              <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="city">
                City<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="mb-4 flex items-center gap-5 text-nowrap">
              <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="postcode">
                Postcode<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="postcode"
                name="postcode"
                value={formData.postcode}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="bg-yellow-500 text-white py-2 text-xl font-bold px-4 w-1/2 rounded-sm hover:bg-yellow-600 focus:outline-none focus:shadow-outline-yellow active:bg-yellow-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DirectPayment;
