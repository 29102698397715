import React, { useState } from 'react';

const AidApplication = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    childName: '',
    bronYear: '',
    cancerType: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form data submitted:', formData);
  };

  return (
    <div>
      <div className='text-center text-5xl pt-16 font-semibold font-serif'>Aid Application</div>

      <div className="max-w-5xl mx-auto p-10 m-10 border border-gray-300">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex items-center gap-5">
            <label className="block text-sm font-semibold text-gray-600 mb-1 text-nowrap" htmlFor="name">
              Parent Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="mobile">
              Mobile Number<span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
            <label className="block text-sm font-semibold text-gray-400 mb-1" >
              (Example 6012-123456789)
            </label>
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="email">
              Email Address<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>
          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="childName">
              Child Name as in Identity Card
            </label>
            <input
              type="text"
              id="childName"
              name="childName"
              value={formData.childName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>

          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="bronYear">
              Born Year
            </label>
            <input
              type="text"
              id="bronYear"
              name="bronYear"
              value={formData.bronYear}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>

          <div className="mb-4 flex items-center gap-5 text-nowrap">
            <label className="block text-sm font-semibold text-gray-600 mb-1" htmlFor="cancerType">
              Cancer Type
            </label>
            <textarea
              id="cancerType"
              name="cancerType"
              value={formData.cancerType}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
              required
            />
          </div>

          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="bg-yellow-500 text-white py-2 text-xl font-bold px-4 w-1/2 rounded-sm hover:bg-yellow-600 focus:outline-none focus:shadow-outline-yellow active:bg-yellow-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AidApplication;
