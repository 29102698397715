import React from 'react'

const Contact = () => {
  return (
    <>
      <div className='text-center text-5xl pt-16 font-semibold font-serif'>Contact</div>
      <div className="my-12 mx-auto">
        <section className="mb-32 text-center">
          <div className="py-12 md:mx-36 border">
            <div className="container mx-auto  ">
              <div className="grid items-center lg:grid-cols-2 md:gap-10 sm:gap-4">
                <div className="md:mb-12 lg:mb-0">
                  <div className="h-[60vh] w-full">
                    <img
                      src="https://www.cclg.org.uk/write/MediaUploads/Support%20us%20section/CCAM/ccam-large-website-banner.png"
                      alt="Your Alt Text"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
                <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                  <div
                  // className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14"
                  >
                    <div className="flex space-y-4 pb-8 flex-col justify-center text-left">
                      <h1 className="text-4xl pbmd:text-3xl font-semibold ">Lions Club of Kajang
                      </h1>
                      <p className="text-gray-700 text-sm md:text-md lg:text-xl ">D1- Block-8, Main Chaudhry Khaliq-uz-Zaman Road, Clifton, Karachi.</p>
                      <p className="text-gray-700 text-sm md:text-md lg:text-xl ">D1- Block-8, Main Chaudhry Khaliq-uz-Zaman Road, Clifton, Karachi.</p>
                      <p className="text-gray-700 text-sm md:text-md lg:text-xl ">D1- Block-8, Main Chaudhry Khaliq-uz-Zaman Road, Clifton, Karachi.</p>
                      <p className="text-gray-700 text-sm md:text-md lg:text-xl ">D1- Block-8, Main Chaudhry Khaliq-uz-Zaman Road, Clifton, Karachi.</p>

                      <div className='text-xl py-4'>
                        <strong className='text-2xl'>Contact Number: </strong> 6012-123456789
                      </div>
                      <div className='text-xl py-4'>
                        <strong className='text-2xl'>General Enquiries </strong>
                        <br />
                        generalenquiries@gmail.com
                      </div>
                      <div className='text-xl pt-4'>
                        <strong className='text-2xl'>Sponsorship Enquiries </strong>
                        <br />
                        sponsorshipenquiries@gmail.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>

  )
}

export default Contact