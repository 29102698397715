export const NavItems = [
    {
        name: 'Aid Application',
        path: '/aid-application'
    },
    // {
    //     name: 'Story',
    //     path: "/story"
    // },
    { 

        name: 'Donation',
        expand: [
            {
                name: 'Direct',
                path: "/payment/direct"
            },
            // {
            //     name: 'Payment Type',
            //     path: "/payment/type"
            // },
            {
                name: 'Join Event',
                path: "/donation/join-event"
            }
        ]
    },
    // {
    //     name: 'Angel Donor',
    //     path: "/angel-donor"
    // },
    { 

        name: 'Bulletin',
        expand: [
            {
                name: 'Press Release',
                path: "/bulletin/press-release"
            },
            {
                name: 'Gallery',
                path: "/bulletin/gallery"
            }
        ]
    },
    { 

        name: 'About',
        expand: [
            {
                name: 'Board of Committees',
                path: "/board-of-committees"
            },
            {
                name: 'Contact',
                path: "/contact"
            }
        ]
    }
]
