import React from 'react'

const JoinEvent = () => {
  return (
    <div>
      <div className='text-center text-5xl py-16 font-semibold font-serif'>Join Event as Donation</div>
      <div className="h-[50vh] w-[90vw] mx-auto">
        <img
          src="https://www.cclg.org.uk/write/MediaUploads/Support%20us%20section/CCAM/ccam-large-website-banner.png"
          alt="Your Alt Text"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="h-[50vh] py-10 w-[90vw] mx-auto">
        <img
          src="https://www.cclg.org.uk/write/MediaUploads/Support%20us%20section/CCAM/ccam-large-website-banner.png"
          alt="Your Alt Text"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  )
}

export default JoinEvent