import { NavItems } from '../../utils/Utils';
import { Link } from 'react-router-dom';
export default function Navbar() {

    return (
        <nav className="bg-white sticky top-0 z-50 border-gray-200 dark:bg-gray-900 dark:border-gray-700">
            <div className="navbar bg-base-100 px-28">
                <div className="flex-1">
                    <Link to={"/"}><img src='/img/logo.png' className='w-20' alt='logo' /></Link>
                </div>
                <ul className="menu menu-horizontal px-1 font-semibold text-[16px]">
                    {
                        NavItems.map((nav, ind) => {
                            return (
                                nav?.expand ? (
                                    <li key={ind} className={`dropdown ${((NavItems.length - 1) === ind) ? "dropdown-bottom dropdown-end" : ""}`}>
                                        <div tabIndex={ind} className="menu text-[16px]">{nav.name}</div>
                                        <ul tabIndex={ind} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-48">
                                            {
                                                nav.expand.map((subNav, i) => (
                                                    <li key={i}><Link to={subNav.path}>{subNav.name}</Link></li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={ind}><Link to={nav.path}>{nav.name}</Link></li>
                                )
                            )
                        })
                    }
                </ul>
            </div>
        </nav>
    );
}


