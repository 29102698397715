import React from 'react'

const PressRelease = () => {
  return (
    <div>
      <div className='text-center text-5xl pt-16 font-semibold font-serif'>Press Release</div>
      <div className='flex flex-col gap-10 mt-16 items-center mx-auto'>
        {
          Array.from(new Array(3)).map((story, ind) => (
            <div key={ind} className="card xl:card-side bg-base-100 shadow-xl">
              <figure><img src="https://daisyui.com/images/stock/photo-1494232410401-ad00d5433cfa.jpg" alt="Album" /></figure>
              <div className="card-body">
                <h2 className="card-title max-w-screen-md text-2xl font-serif">Lions visited Hospital Tunku Azizah (HKL) to hand over urgent supplies to childhood cancer patients</h2>
                <div class="pt-1 pb-8">
                  🗓️ 1st Nov 2023
                </div>
                <p className='max-w-screen-md text-xl font-serif'>On October 25, 2023, the Lions visited Hospital Tunku Azizah (HKL) to hand over urgent supplies for the patients that are battling with childhood cancer. The urgent supplies that were handed over by the Lions included: 20 boxes of Pediasure Milk Powder (600gm/box), 15 packs of Pampers - all sizes, 30 bottles of Difflam Gargle Mouth Wash (100ml./btl.) & 100 pieces of Towels 20" x 40". This project is attended by GET Simon Tan, GST Raymin Yew, and RC Vanessa Tan.</p>
              </div>
            </div>
          ))
        }
        <nav>
          <ul class="list-style-none flex justify-end items-center">
            <li>
              <a
                class="relative block rounded bg-transparent px-3 pb-2 text-3xl text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
                href="#"
                aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {
              Array.from(new Array(3)).map((story, ind) => (
                <li>
                  <div
                    className={`relative block rounded cursor-pointer ${ind === 0 ? "bg-yellow-100" : "bg-transparent "} px-3 py-1.5 text-md text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white`}
                  >
                    {ind + 1}
                  </div>
                </li>
              ))
            }
            <li>
              <a
                class="relative block rounded bg-transparent px-3 pb-2 text-3xl text-neutral-600 transition-all duration-300 hover:bg-neutral-100 dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
                href="#"
                aria-label="Next"
              ><span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default PressRelease